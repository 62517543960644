<template>
  <div>
    <h3>{{ $t('subscription.congratulation.title') }}</h3>
    <div class="mt-5 mb-2">
      {{ $t('subscription.congratulation.email') }}
    </div>
    <v-btn
      color="primary"
      class="black--text mt-4"
      @click="finish"
      :width="342"
    >
      {{ $t('subscription.congratulation.finish') }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Congratulation',
  computed: {
    ...mapGetters('subscription', [
      'subscriptionOrder',
    ]),
    ...mapGetters('assets', {
      products: 'all',
    }),
    product() {
      return this.products.find((item) => item.id === this.$route.params.product);
    },
  },
  methods: {
    finish() {
      this.$router.push({ name: 'Portfolio' });
    },
  },
};
</script>
